import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { HttpClient} from '@angular/common/http';
import { ApiService } from '@app/core/api.service';
import { TokenService } from '@app/core/token.service';
import {map } from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
export class ContentService {
  constructor(
    private api: ApiService,
    private tokenSvc: TokenService,
    private http: HttpClient
  ) {}

  multiplePost(list: Array<{ url: string, data: any }>): Observable<any> {
    const observables: Array<Observable<any>> = [];
    list.forEach(eachRequestObject => {
      observables.push(this.api.postData(eachRequestObject.url, JSON.stringify(eachRequestObject.data)));
    });
    return forkJoin(observables);
  }

  hasErrorMultipleResponse(responses: Array<any>): boolean {
    let response = true;
    for (const res of responses) {
      if (res.status !== 'success') {
        response = false;
        break;
      }
    }
    return response;
  }

  refreshToken(): void {
    const url = 'refresh';
    this.api.getData(url).subscribe(
      res => {
        if (res.token) this.tokenSvc.setToken(res.token);
      },
    );
  }
  postLogin(data: any): Observable<any> {
    const url = 'login/';
    const body = JSON.stringify(data);
    return this.api.postData(url, body);
  }
  
  postartikelId (data: any): Observable<any> {
    const url = 'user/article-by-title/';
    const body = JSON.stringify(data);
    return this.api.postData(url, body);
  }
}
